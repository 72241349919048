<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Benutzer">
      <template #actions>
        <el-input
          v-model="computedSearchQuery"
          placeholder="Durchsuchen…"
          clearable
          style="margin-right: 10px;"
        >
        </el-input>
        <el-button type="primary" icon="fal fa-plus" @click="newUser">
          Neuer Benutzer
        </el-button>
      </template>

      <data-loader
        ref="dataloader"
        :endpoint="
          `/super_admin/users?page=${currentPage}&query=${remoteSearchQuery}`
        "
      >
        <template #loaded="{data}">
          <el-table
            style="width: 100%;"
            empty-text="Keine Ergebnisse"
            stripe
            :data="data.users"
          >
            <el-table-column width="70" prop="id" label="ID"> </el-table-column>
            <el-table-column prop="email" label="E-Mail-Adresse">
            </el-table-column>
            <el-table-column label="Region/Organisation">
              <template #default="scope">
                <span v-if="scope.row.organization">{{
                  scope.row.organization.name
                }}</span>
                <span v-else-if="scope.row.region">{{
                  scope.row.region.name
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Einladung" width="125">
              <template #default="scope">
                <el-tag
                  v-if="
                    !scope.row.invitation_created_at ||
                      scope.row.invitation_accepted_at
                  "
                  size="small"
                  type="success"
                >
                  Angenommen
                </el-tag>
                <el-tag v-else size="small" type="warning">Offen</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="Aktionen" width="260">
              <template #default="scope">
                <el-button
                  icon="fal fa-edit"
                  size="mini"
                  @click="handleEdit(scope.row)"
                >
                  Bearbeiten
                </el-button>
                <delete-button
                  v-slot="slotProps"
                  :on-delete="deleteUser"
                  subject="Benutzer"
                >
                  <el-button
                    size="mini"
                    icon="fal fa-trash-alt"
                    type="danger"
                    @click="slotProps.handleDelete(scope.row)"
                  >
                    Löschen
                  </el-button>
                </delete-button>
              </template>
            </el-table-column>
          </el-table>

          <the-pagination
            v-model="currentPage"
            :total-count="data.total_count"
          ></the-pagination>
        </template>
      </data-loader>
    </page-content>
  </div>
</template>

<script>
import * as log from "loglevel"
import { debounce } from "lodash-es"
import { format, parseISO } from "date-fns"
import SuperAdminUsersRepository from "@/repositories/super_admin/users_repository.js"
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import DataLoader from "@/components/DataLoader"
import ThePagination from "@/components/ThePagination"
import DeleteButton from "@/components/DeleteButton.js"

export default {
  metaInfo: {
    title: "Benutzer"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    DataLoader,
    ThePagination,
    DeleteButton
  },
  beforeRouteUpdate(to, from, next) {
    log.info("view: beforeRouteUpdate")

    if (from.name === to.name) {
      if (to.query.page) {
        log.info(`setting current page to ${to.query.page}`)
        this.currentPage = parseInt(to.query.page)
      } else {
        log.info(`setting current page to 1`)
        this.currentPage = 1
      }

      if (to.query.query) {
        log.info(`setting query to ${to.query.query}`)
        this.localSearchQuery = to.query.query
        this.remoteSearchQuery = to.query.query
      } else {
        log.info(`setting query to ""`)
        this.localSearchQuery = ""
        this.remoteSearchQuery = ""
      }
    }
    next()
  },
  data() {
    return {
      format,
      parseISO,
      currentPage: parseInt(this.$route.query.page) || 1,
      localSearchQuery: this.$route.query.query || "",
      remoteSearchQuery: this.$route.query.query || ""
    }
  },
  computed: {
    computedSearchQuery: {
      get() {
        return this.localSearchQuery
      },
      set(value) {
        this.localSearchQuery = value
        this.debouncedSearch(value)
      }
    }
  },
  methods: {
    debouncedSearch: debounce(
      async function(query) {
        log.info(`Searching for "${query}"…`)
        this.remoteSearchQuery = query
        this.currentPage = 1
        this.$router.push({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            page: 1,
            query: query
          }
        })
      },
      300,
      { maxWait: 3000 }
    ),
    handleEdit(user) {
      this.$router.push({
        name: "SuperAdminEditUserPage",
        params: { id: user.id }
      })
    },
    async deleteUser(user) {
      await SuperAdminUsersRepository.destroy(user.id)
      this.$refs.dataloader.loadData()
    },
    newUser() {
      this.$router.push({ name: "SuperAdminNewUserPage" })
    }
  }
}
</script>
